<template>
  <ProQuestionRow :title="question.title">
    <ProQuestionAnswer :question="question" />
  </ProQuestionRow>
</template>

<script>
import ProQuestionRow from './_ProQuestionRow'
import ProQuestionAnswer from './_ProQuestionAnswer'

export default {
  name: 'ProQuestion',
  components: {
    ProQuestionAnswer,
    ProQuestionRow,
  },
  props: {
    question: { type: Object, required: true },
  },
}
</script>

<style lang="scss" scoped>
li:not(:last-child) {
  margin-bottom: 8px;
}
</style>
