<template>
  <v-row>
    <v-col class="ml-3 py-4 question question-title">
      {{ title }}
    </v-col>
    <v-col class="mr-3 py-4 question question-answer">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProQuestionRow',
  props: {
    title: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.question {
  border-top: 0.5px solid #cecece;
}

.question-title {
  font-size: 14px;
  color: $nn-D_T40;
  font-weight: bold;
  letter-spacing: -0.02em;
}

.question-answer {
  color: $nn-D_T20;
  font-weight: 500;
}
</style>
