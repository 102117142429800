<template>
  <PatientPage :patient-no="patientNo" page-title="PRO questionnaires">
    <template #right-content>
      <WarningBox v-if="isPatientCompleted">
        <strong>Remember:</strong> the patient has ended the trial.
      </WarningBox>
    </template>

    <div v-if="isLoading" class="py-10 text-center">
      <v-progress-circular :size="50" color="grey" indeterminate />
    </div>

    <ProList v-if="showProList" :patient-no="patientNo" />

    <NotEnabled v-if="showNotEnabled" :patient-no="patientNo" />
  </PatientPage>
</template>

<script>
import PatientPage from '@/components/patient/PatientPage'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import loadingState from '@/constants/loadingState'
import ProList from '@/components/proQuestionnaires/ProList'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'

export default {
  name: 'ProQuestionnaires',
  mixins: [trialSettingsGettersMixin, selectedPatientGettersMixin],
  components: {
    NotEnabled: () => import('@/components/layout/NotEnabled.vue'),
    ProList,
    PatientPage,
    WarningBox: () => import('@/components/WarningBox.vue'),
  },
  props: {
    patientNo: {
      type: String,
      required: true,
    },
  },
  computed: {
    prosEnabled() {
      return this.trialFeatureFlags(this.patientNo).prosEnabled
    },
    isLoading() {
      return (
        this.selectedPatientConfigurationLoadingState ===
          loadingState.LOADING ||
        this.selectedPatientConfigurationLoadingState === loadingState.INITIAL
      )
    },
    showProList() {
      return !this.isLoading && this.prosEnabled
    },
    showNotEnabled() {
      return !this.isLoading && !this.prosEnabled
    },
    selectedPatientState() {
      return this.selectedPatientConfiguration(this.patientNo).patientState
        ?.currentState
    },
    isPatientCompleted() {
      return (
        this.selectedPatientState === patientConfigurationPatientState.COMPLETED
      )
    },
  },
}
</script>
