<template>
  <div>
    <div v-if="question.type === 'MultiChoicePicker'">
      <ul>
        <li v-for="(answer, index) in question.answer" :key="index">
          {{ answer }}
        </li>
      </ul>
    </div>
    <div v-else-if="question.type === 'NotAnswered'">
      <ul>
        <li class="red--text">Not answered</li>
      </ul>
    </div>
    <div v-else v-for="(answer, index) in question.answer" :key="index">
      <span v-if="question.type === 'DatePicker'">
        <DateFormat
          :value="answer"
          :format="`d MMM yyyy, ${hourFormatString}`"
        />
      </span>

      <span v-else>
        {{ answer }}
      </span>
    </div>
  </div>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import hourFormatter from '@/utils/date/hourFormatter'
import { selectedPatientMapGetters } from '../../store/modules/selectedPatientModule'

export default {
  name: 'ProQuestionAnswer',
  components: {
    DateFormat,
  },
  props: {
    question: { type: Object, required: true },
  },
  computed: {
    ...selectedPatientMapGetters(),
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
  },
}
</script>

<style scoped></style>
