<template>
  <v-dialog
    v-model="showProAnswerDialog"
    max-width="527"
    persistent
    data-testid="pro-answer-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        depressed
        outlined
        small
        v-on="on"
        data-testid="pro-answer-dialog-open"
        >Open</v-btn
      >
    </template>

    <DialogCard @closeDialog="closeProAnswerDialog">
      <template v-if="isLoading" #title>Loading... Please wait</template>
      <template v-else-if="!showConfirmResend" #title>
        {{ selectedProAnswer.shortName }}
        <span class="ml-2" style="font-weight: normal">{{
          selectedProAnswer.longName
        }}</span>
        <ProHistoryDialog
          :patientNo="patientNo"
          :proPlannedTaskId="proPlannedTaskId"
          :proInfo="{
            shortName: selectedProAnswer.shortName,
            longName: selectedProAnswer.longName,
          }"
          class="ml-2"
        />
      </template>
      <template v-else #title>Confirm re-sending following PRO </template>

      <template #actions>
        <v-btn
          v-if="
            isUserAllowedToManagePatient &&
            selectedProAnswer.status !== proItemStatus.ACTIVE
          "
          :disabled="disableResendBtn"
          :color="inResendProgress ? 'secondary' : 'default'"
          :loading="isLoading"
          @click="resendProNextStep"
          rounded
          depressed
          class="mr-4"
        >
          {{ reSendButtonText }}
        </v-btn>

        <v-btn
          v-if="inResendProgress"
          :color="reSend.isFormValid ? 'default' : 'secondary'"
          rounded
          depressed
          @click="closeResendPro"
          class="elevation-1"
          data-testid="pro-answer-dialog-close"
          >Cancel</v-btn
        >

        <v-btn
          v-if="!inResendProgress"
          color="secondary"
          rounded
          depressed
          @click="closeProAnswerDialog"
          class="elevation-1"
          data-testid="pro-answer-dialog-close"
          >Close</v-btn
        >
        <div ref="bottomDialog" class="bottom-screen"></div>
      </template>

      <div v-if="showConfirmResend">
        <div class="ml-3 py-4">
          <div>
            <v-chip
              color="#EBF2FB"
              label
              small
              class="font-weight-bold mb-3 pro-label"
              >Visit {{ selectedProAnswer.visitId }}</v-chip
            >
          </div>
          <div class="pro-muted-text mb-9">
            Date today: <DateFormat :value="new Date()" />
          </div>

          <div>
            <strong>{{ selectedProAnswer.shortName }}: </strong>
            {{ selectedProAnswer.longName }}
          </div>
        </div>
        <div class="meta-block background-grey mb-12 py-4">
          <div>Reason for re-sending PRO to the patient</div>

          <div class="d-flex align-center justify-start mt-3">
            <div class="review-field rounded-lg d-flex px-4 py-3">
              {{ reasonPreviewText }}
            </div>
          </div>
        </div>
      </div>

      <div class="mb-7" v-if="showAnswers">
        <div ref="scrollElement">
          <v-chip
            color="#EBF2FB"
            label
            small
            class="font-weight-bold mb-3 pro-label"
            >Visit {{ selectedProAnswer.visitId }}</v-chip
          >
          <div class="meta-block pro-muted-text mb-6">
            <div class="mb-1">
              Sent by <i>{{ selectedProAnswer.sentBy.name }}</i> on
              <DateFormat
                :value="selectedProAnswer.sentAt.time"
                :format="`d MMM yyyy, ${timeFormatString}`"
              />
            </div>
            <div>
              <div v-if="selectedProAnswer.submittedAt">
                Patient submitted on
                <DateFormat
                  :value="selectedProAnswer.submittedAt.time"
                  :format="`d MMM yyyy, ${timeFormatString}`"
                />
              </div>
              <div
                v-else-if="selectedProAnswer.status === proItemStatus.EXPIRED"
                class="expired-text"
              >
                Expired in app
                <DateFormat
                  :value="selectedProAnswer.expiresOn.time"
                  :format="`d MMM yyyy, ${timeFormatString}`"
                />
              </div>
            </div>
          </div>
          <div v-if="selectedProAnswer.status === proItemStatus.EXPIRED">
            <strong> The patient has not submitted the questionnaire. </strong>

            <div class="mt-8">
              By resending the PRO the patient will be requested to answer the
              questionnaire again in the app.
            </div>
          </div>
          <div v-else-if="selectedProAnswer.status === proItemStatus.ACTIVE">
            <strong>
              The patient has not yet submitted the re-sent questionnaire. Click
              the audit trial icon to see any previously reported data
            </strong>
          </div>
          <ProQuestion
            v-for="(item, index) in selectedProAnswer.questions"
            :key="`proAnswer_${index}`"
            :question="item"
          />

          <div
            v-if="inResendProgress"
            class="meta-block background-grey mt-4 pt-4"
          >
            Please choose reason for re-sending PRO to the patient
            <v-form v-model="reSend.isFormValid">
              <v-row no-gutters>
                <v-col class="col-12">
                  <v-select
                    v-model="reSend.reason"
                    :items="resendReasonOptions"
                    :rules="resendReasonRules"
                    outlined
                    class="mt-4 rounded-lg"
                    :hide-details="reSend.reason === 'Other'"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-slide-y-reverse-transition :hide-on-leave="true">
                <v-row v-if="reSend.reason === 'Other'" no-gutters class="">
                  <v-col class="col-12">
                    <v-textarea
                      counter
                      outlined
                      class="mt-2 reason-text rounded-lg spacing-2"
                      rows="4"
                      :auto-grow="true"
                      v-model="reSend.reasonProvided"
                      :rules="reasonProvidedRules"
                      :maxlength="reasonProvidedMaxLength"
                      background-color="white"
                      placeholder="Please provide a reason"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-slide-y-reverse-transition>
            </v-form>
          </div>
        </div>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from '../DialogCard'
import DateFormat from '../DateFormat'
import hourFormatter from '@/utils/date/hourFormatter'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import service from '@/services/pro-service'
import loadingState from '@/constants/loadingState'
import proItemStatus from '@/constants/proItemStatus'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'
import ProQuestion from './_ProQuestion'
import Vue from 'vue'
import reasonOtherValidationMixin from '../mixins/reasonOtherValidationMixin'
import ProHistoryDialog from './_ProHistoryDialog'
import permissionsGettersMixin from '../mixins/store/permissionsGettersMixin'

const resendStep = {
  INITIAL: 0,
  CHOOSE_REASON: 1,
  CONFIRM_RESEND: 2,
}

const initialResendModel = () => ({
  step: resendStep.INITIAL,
  reason: null,
  reasonProvided: null,
  isFormValid: false,
})

export default {
  name: 'ProAnswerDialog',
  mixins: [reasonOtherValidationMixin, permissionsGettersMixin],
  components: { ProHistoryDialog, DateFormat, DialogCard, ProQuestion },
  props: {
    patientNo: { type: String, required: true },
    proPlannedTaskId: { type: String, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      selectedProAnswer: {},
      showProAnswerDialog: false,
      reSend: { ...initialResendModel() },
      resendReasonRules: [v => !!v || 'A reason is required'],
      resendReasonOptions: [
        'Patient made a data entry error',
        'Patient misunderstood the PRO',
        'PRO was not sent as per protocol',
        'Other',
      ],
      proItemStatus,
    }
  },
  watch: {
    showProAnswerDialog(newDialog) {
      if (newDialog) {
        this.loadData()
        this.reSend = initialResendModel()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    showAnswers() {
      return (
        this.showProAnswerDialog && !this.isLoading && !this.showConfirmResend
      )
    },
    showConfirmResend() {
      return this.reSend.step === resendStep.CONFIRM_RESEND
    },
    inResendProgress() {
      return this.reSend.step !== resendStep.INITIAL
    },
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    reSendButtonText() {
      return this.reSend.step === resendStep.CONFIRM_RESEND
        ? 'Confirm & re-send'
        : 'Re-send PRO'
    },
    reasonPreviewText() {
      return this.reSend.reason === 'Other'
        ? this.reSend.reasonProvided
        : this.reSend.reason
    },
    patientState() {
      return (
        this.selectedPatientConfiguration(this.patientNo)?.patientState
          ?.currentState || ''
      )
    },
    isPatientCompleted() {
      return this.patientState === patientConfigurationPatientState.COMPLETED
    },
    appState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
    appActive() {
      return this.appState === 'completed'
    },
    disableResendBtn() {
      return (
        (this.inResendProgress && !this.reSend.isFormValid) ||
        !this.appActive ||
        this.isPatientCompleted
      )
    },
  },
  methods: {
    closeResendPro() {
      this.reSend = { ...initialResendModel() }
    },
    resendProNextStep() {
      switch (this.reSend.step) {
        case resendStep.CONFIRM_RESEND:
          this.executeRequest()
          break
        case resendStep.CHOOSE_REASON:
          this.reSend.step = resendStep.CONFIRM_RESEND
          break
        default:
          this.reSend.step = resendStep.CHOOSE_REASON

          this.$nextTick(() => {
            this.$refs.bottomDialog?.scrollIntoView?.({
              behavior: 'smooth',
            })
          })
      }
    },
    closeProAnswerDialog() {
      this.showProAnswerDialog = false
    },
    executeRequest() {
      this.apiError = null
      this.state = loadingState.LOADING

      service
        .reSendPros(
          this.patientNo,
          [this.proPlannedTaskId],
          this.reasonPreviewText
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED

          this.closeProAnswerDialog()
          this.$emit('pros-sent')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.apiError = error
          this.closeConfirmDialog()

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    loadData() {
      this.state = loadingState.LOADING

      service
        .getProItem(this.patientNo, this.proPlannedTaskId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED

          this.selectedProAnswer = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.closeProAnswerDialog()

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.meta-block {
  padding: 6px 34px;
  margin-left: -24px;
  margin-right: -24px;

  &.background-grey {
    background-color: #ccc5b026;
  }
}

.review-field {
  color: $nn-granite-gray;
  background-color: #f1f2f3;
  min-height: 40px;
  border: 1px solid #cccccc;
}
.pro-label {
  margin-top: -18px;
}
.pro-muted-text {
  color: $nn-D_T40;
}
.expired-text {
  color: $nn-lava-red;
}
</style>
