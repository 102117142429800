<template>
  <div>
    <v-card
      v-if="!appActive && !isAllProSent"
      class="rounded-lg no-app-warning pending mb-4"
    >
      <v-card-text class="content">
        <v-row no-gutters class="d-flex" align="center">
          <v-col class="shrink">
            <v-icon>mdi-alert</v-icon>
          </v-col>
          <v-col class="grow pl-2">
            PROs cannot be sent when patient app is not activated.
          </v-col>
          <v-col class="shrink">
            <v-btn
              small
              rounded
              color="warning"
              :to="{ name: 'settingsApp' }"
              type="submit"
              class="elevation-1 btn-close"
              data-testid="btn-close"
              >Go to app settings</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>

    <ProCard
      v-for="(visit, index) in items"
      :visit="visit"
      :patient-no="patientNo"
      :key="`pro-card-${index}`"
      @pros-sent="fetchProList"
    />
  </div>
</template>

<script>
import Vue from 'vue'

import loadingState from '@/constants/loadingState'
import service from '@/services/pro-service'
import globalProgressActionsMixins from '../mixins/store/globalProgressActionsMixins'
import ProCard from './_ProCard'
import selectedPatientGettersMixin from '../mixins/store/selectedPatientGettersMixin'

export default Vue.extend({
  name: 'ProList',
  components: { ProCard },
  mixins: [globalProgressActionsMixins, selectedPatientGettersMixin],
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      loadingState: loadingState.INITIAL,
      items: [],
    }
  },
  computed: {
    isAllProSent() {
      return !this.items.some(v => v.pros.some(x => !x.isSent))
    },
    appState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
    appActive() {
      return this.appState === 'completed'
    },
  },
  methods: {
    fetchProList() {
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()

      service
        .getProsList(this.patientNo)
        .then(response => {
          this.items = response
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
  },
  created() {
    this.fetchProList()
  },
})
</script>

<style lang="scss" scoped>
.no-app-warning {
  &.pending {
    background: $nn-GS_T90;
  }
}
.content {
  font-size: 14px;
  font-weight: 500;
}
</style>
