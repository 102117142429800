<template>
  <v-card class="mb-8 col-12 rounded-lg">
    <v-card-title class="pl-5 pt-2">
      <div class="visit-title spacing-2 mr-2">Visit {{ visit.visitId }}</div>
      <ProAuditDialog
        v-if="isAnyProSent"
        :visit="visit"
        :patient-no="patientNo"
      />
    </v-card-title>
    <div class="pro-card-text">
      <div class="pro-card-header row">
        <v-checkbox
          label="PRO questionnaires"
          color="secondary"
          class="mt-0 small-checkbox"
          :hide-details="true"
          v-model="chkSelectAll"
          :indeterminate="chkAllIndeterminate"
          :disabled="
            isAllProSent ||
            !appActive ||
            isPatientCompleted ||
            !isUserAllowedToManagePatient
          "
        />
      </div>

      <v-row
        v-for="(pro, index) in visit.pros"
        :key="`pro-${pro.proPlannedTaskId}`"
        class="pro-card-item"
        :class="{ 'no-border-top': index === 0 }"
      >
        <div class="action col-7">
          <v-checkbox
            v-if="!pro.isSent"
            :label="pro.shortName"
            color="secondary"
            class="mt-0 mb-0 font-weight-bold"
            :hide-details="true"
            :value="pro.proPlannedTaskId"
            v-model="selected"
            :disabled="
              pro.isSent ||
              !appActive ||
              isPatientCompleted ||
              !isUserAllowedToManagePatient
            "
          />
          <div v-else class="mt-1">
            <div class="checkbox-checked-wrapper">
              <v-icon>mdi-checkbox-marked</v-icon>
            </div>
            <strong class="color-black">{{ pro.shortName }}</strong>
          </div>
          <div class="pl-8 color-black">{{ pro.longName }}</div>
        </div>
        <div class="state col-5 d-flex align-center justify-space-between">
          <v-chip
            v-if="pro.isSent && !pro.isCompleted && !pro.isExpired"
            label
            color="#EBF2FB"
            class="font-weight-medium"
            >Sent to patient
            <DateFormat class="ml-1" :value="pro.sentAt.time" />
          </v-chip>
          <div v-if="pro.isExpired">
            <v-chip label color="#FDEEEC" class="font-weight-medium mr-2">
              <v-avatar left>
                <v-icon small>mdi-bell-outline</v-icon>
              </v-avatar>
              Expired
            </v-chip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="secondary" dark v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span
                >Expired because patient did not <br />complete PRO inside the
                timeframe.</span
              >
            </v-tooltip>
          </div>
          <v-chip
            v-if="pro.isCompleted"
            label
            color="#F1FAF0"
            class="font-weight-medium"
          >
            <v-avatar left>
              <v-icon small color="#2A918B">mdi-check-circle</v-icon>
            </v-avatar>
            Submitted by patient</v-chip
          >
          <ProAnswerDialog
            v-if="pro.isCompleted || pro.isExpired || pro.hasPreviousAnswers"
            :patient-no="patientNo"
            :pro-planned-task-id="pro.proPlannedTaskId"
            @pros-sent="$emit('pros-sent')"
          />
        </div>
      </v-row>

      <div v-if="!isAllProSent && isUserAllowedToManagePatient" class="row">
        <div class="pro-card-actions col-12 text-right">
          <div v-if="!appActive" class="cant-send-inactive mb-3">
            PROs cannot be sent when patient app is not activated
          </div>
          <v-btn
            :disabled="noProsSelected || !appActive || isPatientCompleted"
            @click="showConfirmDialog = true"
            color="secondary"
            rounded
            class="elevation-1"
            >Send PROs</v-btn
          >
        </div>
      </div>
    </div>

    <v-dialog v-model="showConfirmDialog" max-width="479" persistent>
      <DialogCard @closeDialog="showConfirmDialog = false">
        <template #title>
          <v-icon v-if="!visit.isSendExpected" class="mb-1 pr-2 red--text"
            >mdi-alert-circle-outline</v-icon
          >
          Confirm sending following PROs
        </template>
        <template #actions>
          <v-btn
            rounded
            depressed
            @click="showConfirmDialog = false"
            class="mr-4 elevation-1"
            >Cancel</v-btn
          >
          <v-btn
            :color="visit.isSendExpected ? 'secondary' : 'error'"
            rounded
            depressed
            @click="executeRequest"
            :loading="isLoading"
            class="elevation-1"
            >Confirm &amp; send</v-btn
          >
        </template>
        <div class="mb-3">
          <div class="confirm-muted-text mb-3">
            Date: <DateFormat :value="new Date()" />
          </div>
          <div class="confirm-highlighted-text mb-1">
            Visit {{ visit.visitId }}
          </div>
          <div
            v-for="item in selectedListSorted"
            :key="`confirm-pro-${item.id}`"
            class="mb-1"
          >
            <strong>{{ item.shortName }}: </strong>{{ item.longName }}
          </div>
        </div>

        <div v-if="!visit.isSendExpected" class="red--text mb-5">
          Are you sure you want to send PROs from visit
          {{ visit.visitId }} today?
        </div>
      </DialogCard>
    </v-dialog>
  </v-card>
</template>

<script>
import DialogCard from '../DialogCard'
import DateFormat from '../DateFormat'
import service from '@/services/pro-service'
import loadingState from '../../constants/loadingState'
import ProAnswerDialog from './_ProAnswerDialog'
import ProAuditDialog from './_ProAuditDialog'
import Vue from 'vue'
import selectedPatientGettersMixin from '../mixins/store/selectedPatientGettersMixin'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'
import permissionsGettersMixin from '../mixins/store/permissionsGettersMixin'

export default {
  name: 'ProCard',
  mixins: [selectedPatientGettersMixin, permissionsGettersMixin],
  components: { DateFormat, DialogCard, ProAnswerDialog, ProAuditDialog },
  props: {
    visit: { type: Object, required: true },
    patientNo: { type: String, required: true },
  },
  watch: {
    visit() {
      this.selected = this.getAllSelectedList()
    },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      showConfirmDialog: false,
      selected: this.getAllSelectedList(),
    }
  },
  computed: {
    appState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
    selectedPatientState() {
      return this.selectedPatientConfiguration(this.patientNo).patientState
        ?.currentState
    },
    isPatientCompleted() {
      return (
        this.selectedPatientState === patientConfigurationPatientState.COMPLETED
      )
    },
    appActive() {
      return this.appState === 'completed'
    },
    selectedListSorted() {
      let result = []
      for (let i = 0; i <= this.visit.pros.length - 1; i++) {
        const pro = this.visit.pros[i]
        if (this.selected.includes(pro.proPlannedTaskId))
          result.push({
            id: pro.proPlannedTaskId,
            shortName: pro.shortName,
            longName: pro.longName,
          })
      }

      return result
    },
    isAnyProSent() {
      return this.visit.pros.some(x => x.isSent)
    },
    isAllProSent() {
      return this.visit.pros.every(x => x.isSent)
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    noProsSelected() {
      return this.selected.length === 0
    },
    chkAllIndeterminate() {
      return (
        this.selected.length !== 0 &&
        this.selected.length !== this.getAllSelectedList().length
      )
    },
    chkSelectAll: {
      set(val) {
        if (val) {
          this.selected = this.getAllSelectedList()
        } else {
          this.selected = []
        }
      },
      get() {
        return this.selected.length === this.getAllSelectedList().length
      },
    },
  },
  methods: {
    getTypeFromTaskId(taskId) {
      return this.visit.pros.find(x => x.proPlannedTaskId === taskId)?.type
    },
    getAllSelectedList() {
      let result = []
      for (let i = 0; i <= this.visit.pros.length - 1; i++) {
        const pro = this.visit.pros[i]
        if (!pro.isSent) result.push(pro.proPlannedTaskId)
      }

      return result
    },
    closeConfirmDialog() {
      this.showConfirmDialog = false
    },
    executeRequest() {
      this.apiError = null
      this.state = loadingState.LOADING

      service
        .sendPros(this.patientNo, this.selected, 'Initial version')
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED

          this.$emit('pros-sent')
          this.closeConfirmDialog()
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.apiError = error
          this.closeConfirmDialog()

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.visit-title {
  color: $nn-D_T20;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.small-checkbox ::v-deep .v-label {
  font-size: 12px;
  margin-top: 2px;
}
.pro-card-text {
  ::v-deep.v-label {
    color: $nn-black;
  }
  .color-black {
    color: $nn-black;
  }
  .pro-card-header {
    background-color: $nn-TB_T98;
    padding: 6px 33px;
    font-size: 12px;
    color: $nn-D_T20;
    font-weight: bold;
  }

  .pro-card-item {
    border-top: 0.5px solid #cecece;
    .action {
      padding: 14px 3px 14px 33px;

      .checkbox-checked-wrapper {
        display: inline-block;
        margin-right: 8px;
        top: -1px;
      }
    }
    .state {
      padding: 14px 33px 14px 3px;
    }
  }

  .no-border-top {
    border-top: none;
  }

  .pro-card-actions {
    border-top: 0.5px solid #cecece;
    padding: 27px 33px 9px;
  }
}
.confirm-muted-text {
  color: $nn-granite-gray;
  margin-top: -20px;
}
.confirm-highlighted-text {
  color: $nn-sea-blue;
  font-weight: bold;
  font-size: 16px;
}

.cant-send-inactive {
  color: $nn-sea-blue;
  font-size: 14px;
}
</style>
