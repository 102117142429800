<template>
  <v-dialog
    v-model="showDialog"
    :max-width="800"
    :fullscreen="false"
    data-testid="hypo-history-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        color="#f7f6f3"
        class="lighten-5 elevation-1 ml-2"
        v-on="on"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template v-if="isLoading" #title
        ><h4>Loading... Please wait</h4></template
      >
      <template v-else #title
        ><h4>Audit trail for PRO questionnaire</h4></template
      >

      <template #actions>
        <v-btn
          color="secondary"
          rounded
          depressed
          @click="showDialog = false"
          class="elevation-1"
          data-testid="hypo-history-dialog-close"
          >Close</v-btn
        >
      </template>
      <h5 class="mb-5 pl-2">
        {{ proInfo.shortName }}
        <span class="ml-1" style="font-weight: normal">{{
          proInfo.longName
        }}</span>
      </h5>

      <v-progress-linear v-if="isLoading" active indeterminate />
      <div v-else class="sticky-table-wrapper">
        <table v-if="data" :style="`width: ${tableWidth};`">
          <tbody>
            <tr>
              <th :style="`width: ${questionCol}px;`">Edited by patient</th>
              <td
                v-for="(pro, index) in data.items"
                :key="`episode_start_${index}`"
                :style="`width: ${answerCol}px;`"
              >
                <div v-if="pro.status === proItemStatus.ACTIVE">
                  <v-icon small>mdi-alert-circle-outline</v-icon> Not submitted
                </div>
                <div v-else-if="pro.status === proItemStatus.EXPIRED">
                  <v-icon small>mdi-bell-outline</v-icon> Expired
                </div>
                <DateFormat
                  v-if="pro.submittedAt"
                  :value="pro.submittedAt.time"
                  class="mr-5"
                />
                <DateFormat
                  v-if="pro.submittedAt"
                  :format="timeFormatString"
                  :value="pro.submittedAt.time"
                />
              </td>
            </tr>

            <tr>
              <th :style="`width: ${questionCol}px;`">Sent</th>
              <td
                v-for="(pro, index) in data.items"
                :key="`episode_start_${index}`"
                :style="`width: ${answerCol}px;`"
              >
                <DateFormat :value="pro.sentAt.time" class="mr-5" />
                <DateFormat
                  :format="timeFormatString"
                  :value="pro.sentAt.time"
                />
              </td>
            </tr>

            <tr class="border-bottom">
              <th :style="`width: ${questionCol}px;`">By/Reason</th>
              <td
                v-for="(pro, index) in data.items"
                :key="`episode_start_${index}`"
                :style="`width: ${answerCol}px;`"
              >
                <VersionBy :versionBy="pro.sentBy" />
                <i class="d-block mt-1">{{ pro.sentReason }}</i>
              </td>
            </tr>

            <tr v-for="(question, q) in data.questions" :key="`question_${q}`">
              <th>{{ question.title }}</th>
              <td
                v-for="(pro, index) in data.items"
                :key="`answer_${index}_${q}`"
              >
                <ProQuestionAnswer
                  :question="getAnswer(question.id, pro)"
                  :class="{
                    'text-bold': index === 0 && question.changed,
                  }"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import loadingState from '@/constants/loadingState'
import DialogCard from '../DialogCard'
import service from '@/services/pro-service'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import proItemStatus from '@/constants/proItemStatus'
import DateFormat from '../DateFormat'
import VersionBy from '@/components/VersionBy.vue'
import ProQuestionAnswer from './_ProQuestionAnswer'

export default {
  name: 'ProHistoryDialog',
  components: { ProQuestionAnswer, DateFormat, DialogCard, VersionBy },
  props: {
    patientNo: { type: String, required: true },
    proPlannedTaskId: { type: String, required: true },
    proInfo: { type: Object, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
      questionCol: 225,
      answerCol: 225,
      proItemStatus,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    tableData() {
      return this.data?.items || []
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    dateFormatString() {
      return `d MMM yyyy, ${hourFormatter(
        this.selectedPatientClockNotation(this.patientNo)
      )}`
    },
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    tableWidth() {
      if (this.data.items.length < 3) return '100%'

      return this.questionCol + this.data.items.length * this.answerCol + 'px'
    },
  },
  methods: {
    getAnswer(questionId, pro) {
      const result = pro.questions?.find(x => x.id === questionId)
      if (!result) return { type: '', answer: ['-'] }

      return result
    },
    loadData() {
      this.state = loadingState.LOADING
      service
        .getProHistory(this.patientNo, this.proPlannedTaskId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: bold;
}
.sticky-table-wrapper {
  width: 100%;
  overflow-x: scroll;
  position: relative;
  //border-left: 1px solid $nn-D_T80;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    border-top: 1px solid $nn-D_T80;

    tr.border-bottom {
      border-bottom: 1px solid #cecece;
    }
    td,
    th {
      box-shadow: inset 0 -1px 0 0 $nn-D_T80;
      text-align: left;
      padding: 12px 24px;
      font-size: 14px;
      vertical-align: text-top;
      border-left: none !important;
    }

    th {
      color: $nn-granite-gray;
      box-shadow: inset -1px -1px 0 0 $nn-D_T80;
      font-size: 12px;
      padding-left: 4px;
      background-color: white;
    }
    th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    td:last-child {
      box-shadow: inset 0 -1px 0 0 $nn-D_T80;
    }

    td:nth-child(odd) {
      background-color: $nn-TB_T98;
    }

    tr tr:nth-child(even) th:first-child {
      background-color: #f2f2f2;
    }

    tr:nth-child(odd) th:first-child {
      background-color: white;
    }
  }
}
</style>
