<template>
  <v-dialog
    v-model="showDialog"
    :max-width="800"
    data-testid="insulin-audit-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        color="#f7f6f3"
        class="lighten-5 elevation-1"
        v-on="on"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false" class="px-3">
      <template #title><h4>Audit trail for PRO questionnaires</h4></template>

      <div>
        <v-chip
          color="#EBF2FB"
          label
          small
          class="font-weight-bold mb-5 pro-label"
          >Visit {{ visit.visitId }}</v-chip
        >
      </div>

      <div v-if="isLoading">
        <v-progress-linear active indeterminate></v-progress-linear>
        <div class="text-center mt-3">Loading ...</div>
      </div>

      <div v-if="state === loadingState.LOAD_SUCCEEDED">
        <div v-for="(pro, proIndex) in data.pros" :key="`pro_${proIndex}`">
          <div class="mb-6">
            <div class="pb-2" style="border-bottom: 0.5px solid #cecece">
              <strong>{{ pro.shortName }}</strong>
              {{ pro.longName }}
            </div>

            <div class="d-flex justify-space-between data-heading">
              <v-col sm="2" class="pa-2"> Sent </v-col>
              <v-col class="pa-2"> By/reason </v-col>
              <v-col sm="3" class="pa-2"> Submitted by patient </v-col>
            </div>

            <div
              v-for="(item, index) in pro.items"
              :key="`pro_${proIndex}_item_${index}`"
              class="d-flex justify-space-between data-row"
            >
              <v-col sm="2">
                <DateFormat
                  :value="item.sentAt.time"
                  format="dd-MMM-yyyy"
                  data-testid="item-sent-at-date"
                  class="d-block"
                />
                <DateFormat
                  :value="item.sentAt.time"
                  :format="timeFormatString"
                  data-testid="item-sent-at-date"
                  class="d-block mt-1"
                />
              </v-col>
              <v-col>
                <VersionBy :versionBy="item.sentBy" />
                <i class="d-block mt-1">{{ item.versionReason }}</i></v-col
              >
              <v-col sm="3">
                <div v-if="item.isExpired">
                  <v-icon small>mdi-bell-outline</v-icon> Expired
                </div>
                <div v-else-if="!item.submittedAt">
                  <v-icon small>mdi-alert-circle-outline</v-icon> Not submitted
                </div>
                <div v-else class="d-flex justify-start">
                  <div class="mr-1">
                    <v-icon small color="success">mdi-check-circle</v-icon>
                  </div>
                  <div>
                    <DateFormat
                      :value="item.submittedAt.time"
                      format="dd-MMM-yyyy"
                      data-testid="item-sent-at-date"
                      class="d-block"
                    />
                    <DateFormat
                      :value="item.submittedAt.time"
                      :format="timeFormatString"
                      data-testid="item-sent-at-date"
                      class="d-block mt-1"
                    />
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import hourFormatter from '@/utils/date/hourFormatter'

import DateFormat from '../DateFormat.vue'
import VersionBy from '@/components/VersionBy.vue'

import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import loadingState from '@/constants/loadingState'
import DialogCard from '../DialogCard'
import service from '@/services/pro-service'
import Vue from 'vue'
import { selectedPatientMapGetters } from '../../store/modules/selectedPatientModule'

export default {
  name: 'ProAuditDialog',
  components: { DialogCard, VersionBy, DateFormat },
  props: {
    patientNo: { type: String, required: true },
    visit: { type: Object, required: true },
  },
  data() {
    return {
      itemsPerPageOptions,
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    isLoading() {
      return this.state === loadingState.LOADING
    },
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
  },
  methods: {
    loadData() {
      this.state = loadingState.LOADING

      const ids = this.visit?.pros?.map(x => x.proPlannedTaskId)
      service
        .getProsAuditTrail(this.patientNo, ids)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.data-heading {
  font-size: 12px;
  line-height: 18px;
  color: $nn-granite-gray;
}
.data-row {
  background-color: $nn-SG_T95;
  border-bottom: 0.5px solid #cecece;
}
</style>
